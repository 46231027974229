import React, { useState, useEffect } from "react";
import app from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import { Button, Offcanvas } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import GitBookContent from "./GitBookContent";

const db = getFirestore(app);

const HelpAndSupportOverlay = ({ show, onClose, sectionData }) => {
  const [mainTitle, setMainTitle] = useState(sectionData?.mainTitle || "");
  const [learnMoreLink, setLearnMoreLink] = useState(
    sectionData?.learnMoreLink || ""
  );
  const [showLearnMoreLink, setShowLearnMoreLink] = useState(
    sectionData?.showLearnMoreLink || false
  );
  const [published, setPublished] = useState(sectionData?.published || false);
  const [content, setContent] = useState(sectionData?.content || "");

  const location = useLocation(); // Get the current route

  // Fetch existing data from Firestore for the selected route
  const fetchHelpData = async (route) => {
    // Remove only the first '/' from the route
    route = route.replace(/^\//, ""); // This ensures only the first '/' is removed

    // Create a unique key for the document
    const routeKey = route.replace(/\//g, "__SLASH__"); // Replace all '/' with '__SLASH__' in the route

    // Get a document from Firestore
    const docRef = doc(db, "HelpContent", routeKey);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setMainTitle(data.mainTitle || "");
      setLearnMoreLink(data.learnMoreLink || "");
      setContent(data.content || "");
      setPublished(data.published || false);
    } else {
      console.log("No such route found!");
      // Reset to default if no document found
      setMainTitle("");
      setLearnMoreLink("");
      setContent("");
      setPublished(false);
    }
  };

  // Whenever the component is mounted or sectionData changes, update the state
  useEffect(() => {
    if (sectionData) {
      setMainTitle(sectionData.mainTitle || "");
      setLearnMoreLink(sectionData.learnMoreLink || "");
      setShowLearnMoreLink(sectionData.showLearnMoreLink || false);
      setContent(sectionData.content || "");
      setPublished(sectionData.published || false);
    } else {
      // If sectionData is not provided, fetch data based on the current route
      const route = location.pathname;
      fetchHelpData(route);
    }
  }, [sectionData, location.pathname]);

  // const API_KEY = "gb_api_DKWfBjaeSfwEEe8171Mbi4gUhPW2PFzCMKcX8fqQ"; // Replace with your API key
  // const PAGE_ID = "4RVSCEFJnN8KTV3K6HUN"; // Replace with the ID of the page you want to fetch
  // const SPACE_ID = "7ORffsAZdmuCxROZQIky"; // Replace with the ID of the space you want to fetch
  // const PAGE_PATH = "/netnada-overview/our-methodology"; // Replace with the path of the page you want to fetch

  // useEffect(() => {
  //   const fetchContent = async () => {
  //     try {
  //       // const response = await fetch(
  //       //   `https://api.gitbook.com/v1/spaces/${SPACE_ID}/content`,

  //       //   {
  //       //     method: "GET",
  //       //     headers: {
  //       //       Authorization: `Bearer ${API_KEY}`,
  //       //     },
  //       //   }
  //       // );

  //       const response = await fetch(
  //         `https://api.gitbook.com/v1/spaces/${SPACE_ID}/content/page/${PAGE_ID}`,

  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${API_KEY}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       console.log(data);
  //       setContent(data);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchContent();
  // }, [API_KEY, PAGE_ID]);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error fetching content: {error.message}</p>;

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement="end"
      style={{
        zIndex: 1040,
        top: "85px",
        bottom: "85px",
        width: "400px", // Custom width for a sleeker sidebar
      }}
    >
      <Offcanvas.Header
        style={{
          backgroundColor: "#f8f9fa",
          borderBottom: "1px solid #dee2e6", // Border to separate the header
          paddingBottom: "10px", // Extra padding to visually separate the header
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            {/* Custom Icon (Document-like) */}
            <div style={iconContainerStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#13597A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>

            <div className="ms-3">
              {/* Main Title */}
              <h4 className="mb-0">{mainTitle}</h4>
              {/* Learn More Link */}
              {showLearnMoreLink && (
                <Button
                  href={learnMoreLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#fff", // White background
                    color: "#13597A", // Your desired text color
                    fontSize: "1rem",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 12px", // Padding to give the button space
                    border: "1px solid #13597A", // Border with your color
                    borderRadius: "4px", // Optional: for rounded corners
                    marginTop: "5px", // Adjust margin as needed
                    cursor: "pointer",
                  }}
                >
                  Learn more
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    style={{
                      marginLeft: "5px",
                      fontSize: "1rem",
                    }}
                  />
                </Button>
              )}
            </div>
          </div>

          <div>
            {/* Double Right Arrows for closing */}
            <Button
              variant="link"
              onClick={onClose}
              style={{
                fontSize: "1.2rem",
                color: "#000",
                padding: "0",
              }}
            >
              <span>&#x276F;&#x276F;</span> {/* Double right arrows */}
            </Button>
          </div>
        </div>
      </Offcanvas.Header>

      <Offcanvas.Body>
        {/* Sections */}
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div> */}
        {published && content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: content.replace(
                /<iframe[^>]+src="https:\/\/www.loom.com\/share\/([^"]+)"[^>]*><\/iframe>/g,
                `<iframe frameborder="0" allowfullscreen="true" width="100%" height="315" src="https://www.loom.com/embed/$1"></iframe>`
              ),
            }}
          />
        ) : (
          <div style={{ padding: "20px", textAlign: "center" }}>
            <h4>Content Not Published</h4>
            <p>This content is currently in draft mode or unavailable.</p>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HelpAndSupportOverlay;

// Custom Icon Container Style
const iconContainerStyle = {
  backgroundColor: "#F89745", // Orange background
  borderRadius: "6px", // Rounded corners
  padding: "5px",
};
